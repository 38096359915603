@mixin bubble {
    border-radius: 30px;
    background-color: var(--black);
    padding: 0.5rem;
    border: 2px solid var(--white);
    margin: 1rem;
}

@mixin link-state {
    color: var(--white);
    font-family: "Work Sans", sans-serif;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}
@mixin hover-state {
    color: transparent;
    text-shadow: 1px 1px 3px var(--orange);
    transition: all 0.2s ease-in-out;
}
.rsvp-container {
    @include bubble;
    position: fixed;
    bottom: 0;
    right: 0;
    a {
        @include link-state();
        &:hover {
            @include hover-state();
        }
    }
    @media (min-width: 768px){
        left: 0;
        right: unset;
    }
}
