section.schedule-container {
    max-width: var(--max-width);
    margin: 0 auto;
    h2 {
        font-size: 3rem;
        line-height: 3.5rem;
        margin: 0;
        margin-bottom: 0.5rem;
    }
    .subtitle {
        font-size: 2rem;
        padding: 0;
        margin: 0;
    }
}
@media (min-width: 768px) {
    section.schedule-container {
        padding: 0 2rem;
        h2 {
            font-size: 6rem;
            line-height: 6rem;
        }
        .subtitle {
            font-size: 4rem;
        }
    }
}

@media (min-width: 1024px) {
    section.schedule-container {
        padding: 0 2rem;
    }
}

@media (min-width: 1280px) {
    section.schedule-container {
        padding: 0;
    }
}
