@mixin link-state {
    color: var(--white);
    font-family: "Work Sans", sans-serif;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}
@mixin hover-state {
    color: transparent;
    text-shadow: 1px 1px 3px var(--orange);
    transition: all 0.2s ease-in-out;
}

@mixin bubble {
    border-radius: 30px;
    background-color: var(--black);
    padding: 0.5rem 1rem;
    border: 2px solid var(--white);
}

.header-container {
    position: fixed;
    top: 0;
}

header {
    @include bubble;
    width: calc(100vw - 2rem);
    position: relative;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    font-family: "Work Sans", sans-serif;
    font-size: 1.25rem;

    h1 {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;

        a {
            display: flex;
            width: 120px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    span {
        margin-left: 2rem;
        font-size: 1.25rem;
        display: none;
    }
    a {
        cursor: pointer;
        @include link-state;
        &:hover {
            @include hover-state;
        }
        &::selection {
            background: none !important;
            color: var(--white);
            @include hover-state;
        }
    }
}

@media (min-width: 768px) {
    header {
        
        span {
            display: flex;
        }
    }
}
