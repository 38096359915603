.events-container {
    max-width: var(--max-width);
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 2rem;
}

@media (min-width: 600px) {
}
