@mixin link-state {
    color: var(--white);
    font-family: "Work Sans", sans-serif;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}
@mixin hover-state {
    color: transparent;
    text-shadow: 1px 1px 3px var(--orange);
    transition: all 0.2s ease-in-out;
}

.about-container {
    max-width: var(--max-width);
    margin: 5rem auto;
    h2 {
        font-size: 3rem;
        line-height: 3rem;
        margin-bottom: 0.5rem;
    }
    h3 {
        display: inline;
        margin: 0;
        margin-top: 1rem;
    }
    h4 {
        margin: 0;
        margin-top: 1rem;
    }
    hgroup {
        h3 {
            font-size: 2rem;
        }

        @media (min-width: 768px) {
            width: calc(50% - 1rem);
        }
    }
    span {
        font-size: 1rem;
        font-weight: 600;
    }
    .section-header {
        margin-bottom: 2rem;
    }
    details {
        margin-top: 1rem;
        transition: height 1s ease-in-out;
        summary {
            padding: 0.5rem;
            display: inline-block;
            cursor: pointer;
            
        }
    }
    details[open] {
        height: auto;
    }
    details:not([open]) {
        height: auto;
    }
    p {
        font-weight: 400;
        a {
            @include link-state;
            text-decoration: underline;
            &:hover {
                @include hover-state();
            }
        }
    }
    footer {
        font-size: 1rem;
        margin-top: 1rem;
        border-top: 2px solid var(--white);
        padding: 0.5rem 0 0 0;
        @media (min-width: 768px) {
            max-width: 50vw;
        }
    }
    img {
        width: 50vw;
    }
}
