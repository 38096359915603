* {
    box-sizing: border-box;
}
:root {
    --black: #000;
    --white: #fff;
    --orange: #f09e9e;
    --max-width: 1200px;
    --p-max-width: 768px;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

::selection {
    background: var(--orange) !important;
    color: var(--black);
}

:focus {
    outline: 3px solid var(--orange);
}

a::selection, a:focus {
    background: none;
}

body {
    background-color: var(--black);
    color: var(--white);
    font-family: "Work Sans", sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
}

h1,
h2 {
    font-family: "EyebeamSans", sans-serif;
}

h1,
h2,
h3 {
    font-weight: 700;
}

strong,
b {
    font-weight: 700 !important;
}

p {
    font-family: "Work Sans", sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.8rem;
    margin: 0;
    margin-top: 0.5rem;
}

a {
    z-index: 10000;
}

.container {
    animation: fade-in 1s ease;
}

article,
section {
    padding: 0 1rem;

    @media (min-width: 768px) {
        padding: 0 2rem;
    }

    @media (min-width: 1220px) {
        padding: 0;
    }
}

.container {
    width: 100vw;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
