.event-container {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100%);
    margin-bottom: 1rem;
    font-size: 1.25rem;
    font-family: "Work Sans", sans-serif;
    font-weight: 600;
    border: 2px solid var(--white);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .event-top {
        display: flex;
        flex-flow: row nowrap;
        flex: 0 0 auto;
        .event-date-wrapper {
            background-color: var(--white);
            color: var(--black);
            display: flex;
            flex: 0 0 25%;
            height: 8rem;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            padding: 1rem;
            border-radius: 0 0 10px 0;
            font-weight: 600;

            .event-date,
            .event-day,
            .event-month {
                display: flex;
                text-transform: uppercase;
            }

            .event-day,
            .event-month {
                font-size: 1.6rem;
                line-height: 1rem;
            }

            .event-date {
                font-size: 3rem;
                padding: 0;
            }
        }
        .event-title {
            padding: 1rem;
            font-size: 1.25rem;
            font-family: "EyebeamSans", sans-serif;
            font-weight: 800;
            .event-subtitle {
                margin-top: 0.5rem;
                font-size: 1.25rem;
                font-family: "Work Sans", sans-serif;
                font-weight: 600;
                flex: 1 0 100%;
            }
        }
    }
    .event-artists,
    .event-talks,
    .event-conversations,
    .event-host {
        padding: 0 1rem;

        h3 {
            font-weight: 400;
            margin: 1rem 0 0.5rem 0;
            font-size: 1.5rem;
        }
        p {
            font-weight: 400;
        }
        span {
            font-weight: 700;
        }
        .artists {
            font-size: 1.5rem;
            margin-top: 0.5rem;

            a {
                color: var(--white);
                font-family: "Work Sans", sans-serif;
                text-decoration: none;
                transition: all 0.2s ease-in-out;
                &:hover {
                    color: transparent;
                    text-shadow: 1px 1px 3px var(--orange);
                    transition: all 0.2s ease-in-out;
                }
            }
        }
    }
    .event-host {
        padding-bottom: 1rem;
    }
}

@media (min-width: 600px) {
    .event-container {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(50% - 0.5rem);
        .event-top {
            .event-title {
                font-size: 1.5rem;
                .event-subtitle {
                    font-size: 1.25rem;
                }
            }
        }
        .event-artists {
            font-size: 1.25rem;
        }
    }
}

@media (min-width: 1200px) {
    .event-container {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(50% - 0.5rem);
        .event-top {
            .event-title {
                font-size: 1.5rem;
                padding: 1rem;
                .event-subtitle {
                    font-size: 1.25rem;
                }
            }
        }
        .event-artists {
            font-size: 1.25rem !important;
        }
    }
}
