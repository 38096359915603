.hero-container {
    display: flex;
    justify-content: center;
    z-index: -1;
    position: relative;
    align-items: center;
    height: 100vh;

    .grid {
        transform: scale(.3);
        display: flex;
        justify-content: center;

        .glyph {
            position: absolute;
            top: 215px;
            transform: scale(1.675);
        }
        .loading {
            position: absolute;
            z-index: 100;
            top: 300px;
            transform: translateX(15px) scale(0.7);
        }
    }

    @media(min-width: 768px) and (orientation: portrait){
        .grid {
            transform: scale(.6);
        }
    }

    @media (min-width: 857px){
        height: 768px;

        .grid {
            transform: scale(.5);
        }
    }
    @media (orientation: landscape){
        height: 100vh;
        margin-top: 2rem;
    }
}
