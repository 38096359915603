.cursor {
    display: block;
    position: absolute;
    z-index: 1000;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: white;
    mix-blend-mode: exclusion;
    transition: all 100ms ease;
    &.no-effects {
        mix-blend-mode: unset;
        display: none;
    }
}
