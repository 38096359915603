@mixin link-state {
    color: var(--white);
    font-family: "Work Sans", sans-serif;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    user-select: none;
}
@mixin hover-state {
    color: transparent;
    text-shadow: 1px 1px 3px var(--orange);
    transition: all 0.2s ease-in-out;
}
@mixin bubble {
    border-radius: 30px;
    background-color: var(--black);
    padding: 0.5rem 1rem;
    border: 2px solid var(--white);
    display: flex;
    margin: 1rem;
}

@mixin navigation-closed {
    overflow: hidden;
    transition: transform 250ms ease;
    transform: translateY(-300%);
}
@mixin navigation-open {
    margin: 1rem !important;
    height: auto !important;
    transition: transform 250ms ease;
    transform: unset;
    border: 2px solid var(--white);
}

nav {
    
    ul {
        @include bubble();
        @include navigation-closed();
        position: relative;
        z-index: 10;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        list-style-type: none;
        font-size: 1rem;
        li {
            a {
                @include link-state();
                &:hover {
                    @include hover-state();
                }
                
            }
        }
    }
}

nav {
    ul.open {
        @include navigation-open();
    }
}

@media (min-width: 400px) {
    nav {
        ul {
            flex: 1 0 33%;
            font-size: 1.25rem;
        }
    }
}

@media (min-width: 1200px) {
    nav {
        ul {
            font-size: 1.25rem;
            flex: 0 0 33% !important;
        }
    }
}

@media (min-width: 1400px) {
    nav {
        ul {
            font-size: 1.25rem;
            flex: 0 0 33% !important;
        }
    }
}

@media (min-width: 768px) {
}
